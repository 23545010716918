<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        md="4"
        offset="4"
      >
        <!-- <analytics-congratulation/> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"
        v-if="iCan('job-offers', 'write')"
      >
        <b-link :to="{name: 'create-edit-job-offer', params: {id: 'new'}}">
            <statistic-card-vertical
              color="primary"
              icon="BriefcaseIcon"
              :statistic="i18nT(`Job offer`)"
              :statistic-title="i18nT(`Create new job offer`)"
            />
        </b-link>
      </b-col>
      <b-col md="3"
             v-if="iCan('applications', 'write')"
      >
        <b-link :to="{name: 'create-applications'}">
          <statistic-card-vertical
            color="primary"
            icon="UserIcon"
            :statistic="i18nT(`Candidate`)"
            :statistic-title="i18nT(`Create new candidate`)"
          />
        </b-link>
      </b-col>
      <b-col md="3"
             v-if="iCan('calendar', 'write')"
      >
        <b-link 
          :to="{
            name: `create-task`,
            params: {software: 're'}
          }">
          <statistic-card-vertical
            color="primary"
            icon="CheckCircleIcon"
            :statistic="i18nT(`Task`)"
            :statistic-title="i18nT(`Create new task`)"
          />
        </b-link>
      </b-col>
      <b-col md="3">
        <b-link 
          :to="{
            name: `inbox-new`,
            params: {software: 're'}
          }">
          <statistic-card-vertical
            color="primary"
            icon="MailIcon"
            :statistic="i18nT(`Message`)"
            :statistic-title="i18nT(`Send new message`)"
          />
        </b-link>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4" v-if="iCan('applications', 'read')">
        <analytics-applicants :data="candidates" />
      </b-col>
      <b-col lg="4" v-if="iCan('job-offers', 'read')">
        <analytics-openings :data="offers" />
      </b-col>
      <b-col lg="4">
        <analytics-messages
          :data="messages"
          manageLink="/re/inbox"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BLink } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import AnalyticsCongratulation from './analytics/AnalyticsCongratulation.vue'
import AnalyticsApplicants from './analytics/AnalyticsApplicants.vue'
import AnalyticsMessages from './analytics/AnalyticsMessages.vue'
import AnalyticsOpenings from './analytics/AnalyticsOpenings.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StaticCardFA'
import _ from 'lodash'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    AnalyticsCongratulation,
    AnalyticsApplicants,
    AnalyticsOpenings,
    StatisticCardVertical,
    AnalyticsMessages
  },
  data() {
    return {
      data: {},
      candidates: {
        applications: [],
        count: 0
      },
      offers: {
        offers: [],
        count: 0
      },
      messages: {
        messages: [],
        count: 0
      },
      intro: null
    }
  },
  created() {
    // this.$http.get(
    //   `candidates/applicants`
    // ).then(({data}) => {
    //   this.candidates = data.data.applicants
    //   this.intro = {
    //     author: data.data.intro_author,
    //     text: data.data.intro_text
    //   }
    // })

    this.$http.get(`offers/jobOffers`).then(({ data }) => {
      // this.offers = data.data.offers
    })

    this.$http.get(`messages`).then(({ data }) => {
      // this.messages = _.values(data.data)
    })

    this.$http.get(`main/reDashboard`).then(({ data }) => {
      this.messages = {
        messages: data.data.messages,
        count: data.data.messages_count
      }
      this.offers = {
        offers: data.data.offers.offers,
        count: data.data.offers_count
      }
      this.candidates = {
          applications: data.data.applicants,
          count: data.data.applications_count
      }
      this.intro = {
        author: data.data.intro_author,
        text: data.data.intro_text
      }
    })
  },
  methods: {
    kFormatter
  }
}
</script>
