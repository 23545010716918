<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="ml-25">
        {{ data.count }} {{i18nT(`Job offers`)}}
      </b-card-title>
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <b-media
        no-body
        v-for="offer in data.offers"
        :key="offer.Id"
        class="media pb-50 pt-50 border-bottom"
      >
        <b-media-body class="my-auto">
          <b-link
            :to="{ name: 'view-job-offer', params: { id: offer.Id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ offer.Label }}
          </b-link>
          <!-- <b-badge variant="success">
              {{ offer.PublicationStatus }}
            </b-badge> -->
          <p class="mb-0">
            {{ offer.CategoryTitle }}
          </p>
        </b-media-body>
        <!--<b-media-aside class="">
          <b-form-checkbox
            :checked="offer.PublicationStatus === 'ACTIVE'"
            name="check-button"
            switch
            inline
            @change="changeOfferStatus(offer)"
          >
          </b-form-checkbox>
      </b-media-aside>-->
      </b-media>
      <b-media
        style="text-align: center"
        v-if="data.count === 0"
      >
        <span
          class="b-avatar badge-light-secondary rounded mb-2 mt-4"
          style="width: 70px; height: 70px;"
        >
          <feather-icon
            icon="BookIcon"
            size="36"
          />
        </span>
        <h5>{{i18nT(`Nothing new`)}}</h5>
        <p class="text-muted"> {{i18nT(`There are no job offers.`)}}</p>
      </b-media>
    </b-card-body>
    <a
      href="/re/job-offers"
      style="padding: 8px"
    >
      <b-button
        variant="outline-secondary"
        style="width: 100%"
      >
        {{i18nT(`See all`)}}
      </b-button>
    </a>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BBadge,
  BFormCheckbox,
  BButton,
  BLink
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    // BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    // AppTimeline,
    // AppTimelineItem,
    BMedia,
    BMediaBody,
    // BMediaAside,
    // BAvatarGroup,
    BBadge,
    BFormCheckbox,
    BMediaAside,
    BButton,
    BLink
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          count: 0,
          offers: []
        }
      }
    }
  },
  computed: {
    activeJobs() {
      return this.data.filter(d => d.PublicationStatus === 'ACTIVE')
    }
  },
  methods: {
    changeOfferStatus(offer) {
      this.$http
        .post(
          `offers/changeStatus?id=${offer.Id}&status=${
            offer.PublicationStatus === 'ACTIVE' ? 'DEACTIVATED' : 'ACTIVE'
          }`
        )
        .then(response => {
          console.log(response)
        })
    }
  }
}
</script>
