<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="ml-25">
        {{data.count}} {{i18nT(`New applicants`)}}
      </b-card-title>
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <b-media
        no-body
        v-for="offer in data.applications"
        :key="offer.Id"
        class="media pb-50 pt-50 border-bottom"
      >
        <b-media-aside>
          <b-avatar
            :src="offer.UserObj.ImageUrl"
            :text="(offer.UserObj.FirstName ? offer.UserObj.FirstName.charAt(0) : '') + (offer.UserObj.LastName ? offer.UserObj.LastName.charAt(0) : '')"
            class="badge-light-primary"
            size="38"
          />
        </b-media-aside>
        <b-media-body class="my-auto">
          <b-link
            :to="{ name: 'view-application', params: { id: offer.UserId }, query: { offerId: offer.OfferId } }"
            class="font-weight-bold d-block text-nowrap"
          >
            <span class="bullet bullet-sm bullet-success"></span>
            {{ offer.UserObj.Label }}
          </b-link>
          <p
            class="mb-0"
            v-if="offer.OfferObj"
          >
            {{i18nT(`For`)}} {{ offer.OfferObj.Label }}
          </p>
        </b-media-body>
      </b-media>
      <b-media
        style="text-align: center;"
        v-if="data.count === 0"
      >
        <span
          class="b-avatar badge-light-secondary rounded mb-2 mt-4"
          style="width: 70px; height: 70px;"
        >
          <feather-icon
            icon="UsersIcon"
            size="36"
          />
        </span>
        <h5>{{i18nT(`Nothing new`)}}</h5>
        <p class="text-muted"> {{i18nT(`There are no new applicants.`)}}</p>
      </b-media>
      <!--/ timeline -->
    </b-card-body>
    <a
      href="/re/applications"
      style="padding: 8px;"
    >
      <b-button
        variant="outline-secondary"
        style="width: 100%;"
      >
        {{i18nT(`See all`)}}
      </b-button>
    </a>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BLink,
  VBTooltip,
  BBadge,
  BButton
} from 'bootstrap-vue'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BBadge,
    BButton,
    BLink
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        count: 0,
        applications: []
      })
    }
  }
}
</script>
